var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TextWithImage" }, [
    _c("div", { staticClass: "TextWithImage__container" }, [
      _c(
        "div",
        {
          staticClass: "TextWithImage__grid ",
          class:
            ((_obj = { "TextWithImage__grid--reverse": _vm.reverse }),
            (_obj["TextWithImage__grid--" + _vm.size] = true),
            _obj),
        },
        [
          _c("cms-text-link-block", { staticClass: "TextWithImage__col" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.bodyText) } }),
          ]),
          _vm._v(" "),
          _vm.image && !_vm.video
            ? _c(
                "div",
                { staticClass: "TextWithImage__col" },
                [_c("image-tag", { attrs: { image: _vm.image } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.video
            ? _c(
                "div",
                { staticClass: "TextWithImage__col" },
                [
                  !_vm.isVimeo && !_vm.isYouTube
                    ? _c("video-embed", {
                        attrs: {
                          "is-hero": false,
                          "media-file": _vm.video.url,
                          muted: false,
                          poster: _vm.poster,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isYouTube
                    ? _c("video-youtube", {
                        attrs: { "you-tube-id": _vm.video.video.videoId },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isVimeo
                    ? _c("video-vimeo", {
                        attrs: { "vimeo-id": _vm.video.video.videoId },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }