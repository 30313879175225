







































import {PlainObject} from '@labor-digital/helferlein';
import {isNull} from '@labor-digital/helferlein/lib/Types/isNull';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import CmsTextLinkBlock from '../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';
import VideoEmbed from '../../../Component/Misc/Video/VideoEmbed/VideoEmbed.vue';
import VideoVimeo from '../../../Component/Misc/Video/VideoVimeo/VideoVimeo.vue';
import VideoYoutube from '../../../Component/Misc/Video/VideoYoutube/VideoYoutube.vue';

export default {
    name: 'TextWithImage',
    components: {VideoYoutube, VideoVimeo, VideoEmbed, ImageTag, CmsTextLinkBlock},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        reverse(): boolean
        {
            return this.context.data.get('reverse', false);
        },
        size(): boolean
        {
            return this.context.data.get('size', 48);
        },
        bodyText(): string
        {
            return this.context.data.get('bodyText', '');
        },
        image(): PlainObject
        {
            return this.context.data.get('image', null);
        },
        video(): PlainObject
        {
            return this.context.data.get('video', null);
        },
        isVimeo()
        {
            return isNull(this.video) ? false : this.video.video.isVimeo;
        },
        isYouTube()
        {
            return isNull(this.video) ? false : this.video.video.isYouTube;
        },
        poster()
        {
            return isNull(this.image) ? null : this.image.url;
        }
    }
};
